export function Keystore() {
  return (
    <span className="flex items-center gap-x-0.5 text-acc">
      <svg
        id="Capa_1"
        data-name="Capa 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 1000"
        height={42}
        width={42}
      >
        <defs>
          <style>
            {`
      .cls-1 {
        fill: currentColor;
      }
        `}
          </style>
        </defs>
        <polygon
          className="cls-1"
          points="758.66 303.94 700.99 385.34 582.33 455.39 601.62 570.2 500.05 639.55 398.48 570.2 417.76 455.39 299.1 385.34 241.41 303.94 447.36 369.02 425.03 535.52 500.05 564.73 575.06 535.52 552.74 369.02 758.66 303.94"
        />
        <polygon
          className="cls-1"
          points="500.05 639.55 438.99 673.19 298.92 570.2 222.9 297.24 204.3 289.64 165.67 519.67 500.05 790.36 834.39 519.67 795.74 289.64 777.19 297.24 701.11 570.2 561.09 673.19 500.05 639.55"
        />
      </svg>
      <span className="relative font-bold text-[15px] text-acc uppercase">
        Keystore
      </span>
    </span>
  );
}

