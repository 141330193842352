import classNames from "classnames";
import { HiveKeychain, HiveSigner } from "./HiveLogos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Keystore } from "./Keystore";

interface HiveSignProps {
  method: "hivesigner" | "keychain" | "keystore";
  loading?: boolean;
  onClick: () => void;
}

export default function HiveSign({ method, loading, onClick }: HiveSignProps) {
  const icons = {
    hivesigner: <HiveSigner />,
    keychain: <HiveKeychain />,
    keystore: <Keystore />
  };

  return (
    <button
      type="button"
      title={method}
      aria-label={method}
      className={classNames(
        "relative flex items-center justify-center py-1 px-1 hover:bg-red-400/10 rounded-lg disabled:cursor-not-allowed transition-colors duration-150",
        {
          "w-40 h-11": method === "hivesigner",
          "w-36 h-11": method === "keychain"
        }
      )}
      onClick={() => onClick()}
      disabled={loading}
    >
      {icons[method]}

      {loading && (
        <span className="absolute inset-0 w-full h-full flex justify-center items-center bg-black/50">
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        </span>
      )}
    </button>
  );
}

