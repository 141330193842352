export function Divider() {
  return (
    <div className="relative flex justify-center items-center w-full">
      <span className="absolute left-0 w-2/5 border-b border-pri/50 dark:border-pri-d/50" />
      <span className="px-3 bg-pri dark:bg-pri-d text-xs font-semibold text-pri/50 dark:text-pri-d/50 uppercase z-10">
        or
      </span>
      <span className="absolute right-0 w-2/5 border-b border-pri/50 dark:border-pri-d/50" />
    </div>
  );
}

export function FlatDivider() {
  return (
    <div className="relative flex justify-center items-center w-full border-b border-pri/50 dark:border-pri-d/50" />
  );
}

export function VerticalMarquee() {
  const text = "INLEO".repeat(16);

  return (
    <div className="fixed top-0 bottom-0 right-0 left-0 sm:left-[unset] h-[100vw] sm:rotate-90 pointer-events-none select-none -z-10">
      <div className="relative w-screen h-[224px] overflow-hidden">
        <div className="flex flex-1 flex-col whitespace-nowrap animate-marquee">
          <div className="text-[144px] font-black !font-display leading-[0.775] text-pri/[.075] dark:text-pri-d/[.075]">
            {text}
          </div>
        </div>
        <div className="flex flex-1 flex-col whitespace-nowrap animate-marquee-reverse">
          <div className="text-[144px] font-black !font-display leading-[0.78] text-pri/[.075] dark:text-pri-d/[.075] -translate-x-[360px]">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
}
