import { useState } from "react";
import { useGetRedirectTo, writeLocalStorageAccounts } from "~/routes/login";
import * as keychain from "~/utils/keychain";
import * as hivesigner from "~/utils/hivesigner";
import HiveSign from "./HiveSign";
import KeychainAccountModal from "./hive/KeychainAccountModal";
import { useNavigate } from "@remix-run/react";

export default function HiveLogin() {
  const redirectTo = useGetRedirectTo();
  const navigate = useNavigate();

  const [keychainVisibility, setKeychainVisibilty] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [keychainLoading, setKeychainLoading] = useState(false);
  const [hivesignerLoading, setHivesignerLoading] = useState(false);

  const handleKeychainLogin = async () => {
    if (accountName === "") {
      return;
    }

    setKeychainLoading(true);

    try {
      const message = await keychain.createSignToken(accountName);
      void (async function () {
        window.localStorage.setItem("activeAccount", message);
      })();

      writeLocalStorageAccounts(accountName);

      const formData = new FormData();
      formData.append("message", message);
      formData.append("accountName", accountName);
      formData.append("redirectTo", redirectTo);
      let response = await fetch("/login", {
        method: "POST",
        body: formData,
        redirect: "follow"
      });
      window.location.href = response.url;
    } catch (err) {
      setKeychainLoading(false);
    }

    setKeychainLoading(false);
  };

  const handleHivesignerLogin = async () => {
    setHivesignerLoading(true);

    try {
      hivesigner.createSignToken();
    } catch (err) {
      setHivesignerLoading(false);
    }

    setHivesignerLoading(false);
  };

  return (
    <div className="flex flex-1 items-center justify-around gap-x-4 -mt-2">
      <HiveSign method="keychain" onClick={() => setKeychainVisibilty(true)} loading={keychainLoading} />
      <HiveSign method="hivesigner" onClick={() => handleHivesignerLogin()} loading={hivesignerLoading} />
      <HiveSign
        method="keystore"
        onClick={() => navigate(`/keystore?redirectTo=${redirectTo}&action=login`)}
        loading={false}
      />

      <KeychainAccountModal
        visibility={keychainVisibility}
        username={accountName}
        loading={keychainLoading}
        setVisibility={setKeychainVisibilty}
        setUsername={(username: string) => setAccountName(username)}
        onSubmit={handleKeychainLogin}
      />
    </div>
  );
}
